import { UNIT_TYPE } from '../constans/unitsConstans'


export const converterToMetricSystem = (
    type: string,
    value: number
): number => {
    switch (type) {
        case UNIT_TYPE.speed:
            return Number.parseFloat((value * 1.609344001).toFixed(2))

        case UNIT_TYPE.distance:
            return Number.parseFloat((value * 0.3048).toFixed(2))

        case UNIT_TYPE.fluidity:
        case UNIT_TYPE.density:
            return Number.parseFloat((value * 0.621371192).toFixed(2))

        default:
            return value
    }
}

export const converterToImperialSystem = (
    type: string,
    value: number
): number => {
    switch (type) {
        case UNIT_TYPE.speed:
            return Number.parseFloat((value * 0.621371192).toFixed(2))

        case UNIT_TYPE.distance:
            return Number.parseFloat((value * 3.280839895).toFixed(2))

        case UNIT_TYPE.fluidity:
        case UNIT_TYPE.density:
            return Number.parseFloat((value * 1.609344001).toFixed(2))

        default:
            return value
    }
}