import React, { SyntheticEvent, useEffect, useState } from 'react'
import { Pause, PlayArrow } from '@mui/icons-material'
import { addTimeToDate } from '../../helpers/DateTimeHelper'
import {
    HorizonSliderWrapper,
    SelectedTimeTypography,
    SliderIconButton,
    SliderWrapper,
    SliderStyled,
} from './horizonSliderStyles'
import { DateTime } from 'luxon'
import { useAppSelector, useFetchingData } from '../../app/hooks'
import { selectHorizon } from './horizonSilderSlice'
import { selectConfig, selectEpoch, selectTimeZone } from '../core/coreSlice'
import { StyledBarLoader } from '../pattern/patternStyles'

const HorizonSlider: React.FC<IHorizonSliderProps> = ({
    isDisabled,
    changeHorizonAndDate,
    horizonStep,
    marks,
}: IHorizonSliderProps) => {
    const _epoch = useAppSelector(selectEpoch)
    const _timeZone: string = useAppSelector(selectTimeZone)
    const _moduleConfig: IModuleConfig = useAppSelector(selectConfig)
    const _horizon: number = useAppSelector(selectHorizon)
    const [timeLapseAnimation, setTimeLapseAnimation] = React.useState(false)
    const [horizonValue, setHorizonValue] = useState(_horizon)
    const [date, setDate] = useState<DateTime>(DateTime.fromMillis(_epoch, { zone: _timeZone }))
    const _fetchingData = useFetchingData()
    const [fetching, setFetching] = useState<boolean>(true)

    const { time: timeFormat, date: dateFormat } = _moduleConfig.date_format
    const maxHorizon = marks[marks.length - 1].value

    useEffect(() => {
        setEpoch(_epoch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_epoch])

    useEffect(() => {
        if (_horizon === 0) {
            setHorizonValue(marks[0].value)
            setDate(DateTime.fromMillis(_epoch, { zone: _timeZone }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_horizon])

    useEffect(() => {
        if (_timeZone && _timeZone !== 'local') {
            setFetching(false)
            setEpoch(_epoch)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_timeZone])

    useEffect(() => {
        let intervalID: any = null
        if (timeLapseAnimation) {
            intervalID = setInterval(() => {
                if (horizonValue >= maxHorizon) {
                    setTimeLapseAnimation(false)
                }
                const nextHorizon = horizonValue + horizonStep
                if (nextHorizon <= maxHorizon) {
                    const nextDate = addTimeToDate(
                        DateTime.fromMillis(_epoch, { zone: _timeZone }),
                        'minutes',
                        nextHorizon
                    )
                    setHorizonValue(nextHorizon)
                    setDate(nextDate)
                    changeHorizonAndDate(nextHorizon, nextDate)
                }
            }, 2000)
        } else {
            clearInterval(intervalID)
        }
        return () => clearInterval(intervalID)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeLapseAnimation, horizonValue, changeHorizonAndDate, maxHorizon, horizonStep])

    const setEpoch = (epoch: number) => {
        if (epoch !== 0) {
            const startHorizon = marks[0].value
            const nextDate = addTimeToDate(DateTime.fromMillis(_epoch, { zone: _timeZone }), 'minutes', startHorizon)
            setDate(nextDate)
        }
    }

    const startTimelapse = () => {
        setTimeLapseAnimation(true)

        if (horizonValue === maxHorizon) {
            setHorizonValue(marks[0].value)
            const nextDate = addTimeToDate(DateTime.fromMillis(_epoch), 'minutes', marks[0].value)
            setDate(nextDate)
            changeHorizonAndDate(marks[0].value, nextDate)
        }
    }

    const stopTimelapse = () => {
        setTimeLapseAnimation(false)
    }

    const sliderManualChange = (event: Event | SyntheticEvent<Element, Event>, value: number | number[]) => {
        event.preventDefault()
        const horizonSelected: number = typeof value === 'number' ? value : 0
        const nextDate: DateTime = addTimeToDate(
            DateTime.fromMillis(_epoch, { zone: _timeZone }),
            'minutes',
            horizonSelected
        )

        setHorizonValue(horizonSelected)
        setDate(nextDate)
        changeHorizonAndDate(horizonSelected, nextDate)
    }

    const dateTimeFormat = `${dateFormat} ${timeFormat}`
    const dateFormatted: string = date.toFormat(dateTimeFormat)

    return (
        <HorizonSliderWrapper>
            {!fetching && <SelectedTimeTypography startonmark={1}>{dateFormatted}</SelectedTimeTypography>}
            {fetching && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <StyledBarLoader height={5} width={100} />
                </div>
            )}
            <SliderWrapper>
                {timeLapseAnimation && (
                    <SliderIconButton disabled={isDisabled || _fetchingData} onClick={stopTimelapse}>
                        <Pause />
                    </SliderIconButton>
                )}
                {!timeLapseAnimation && (
                    <SliderIconButton disabled={isDisabled || _fetchingData} onClick={startTimelapse}>
                        <PlayArrow />
                    </SliderIconButton>
                )}
                <SliderStyled
                    value={horizonValue}
                    defaultValue={marks[0].value}
                    step={horizonStep}
                    min={marks[0].value}
                    max={marks[marks.length - 1].value}
                    size='small'
                    valueLabelDisplay='off'
                    marks={marks}
                    onChangeCommitted={(ev, value) => sliderManualChange(ev, value)}
                    disabled={isDisabled || _fetchingData}
                    startonmark={1}
                    disableSwap={false}
                />
            </SliderWrapper>
        </HorizonSliderWrapper>
    )
}

export default HorizonSlider
