import { all, AllEffect, call, CallEffect, ForkEffect, put, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { getKpisResponsePlans } from '../../../../../services/kpis/kpisResponsePlanService'
import { loadRPKpi, setFetchingRPKpis, storeRPKpis } from './kpisResponsePlansSlice'
import responsePlanSelector from '../../responsePlanSelector/responsePlanSelector'

function* fetchRPKpis(action: any): any {
    const { selectedEvaluation, isStaticMode } = action.payload
    let fetchAllKpis: { [p: string]: CallEffect<AxiosResponse<number, any>> } = {}
    selectedEvaluation.responsePlans &&
        selectedEvaluation.responsePlans.forEach((rp: { description: string; responsePlanId: number } | string | IResponsePlan) => {
            let _rpId = ''
            if (rp && typeof rp === 'object') {
                if ('responsePlanId' in rp) {
                    _rpId =  rp.responsePlanId || rp.responsePlanId === 0 ? rp.responsePlanId.toString() : ''
                }
            } else {
                _rpId = rp
            }
            const params: any = {
                t: selectedEvaluation.epoch,
                rp: _rpId,
            }
            if (isStaticMode) {
                params.event = selectedEvaluation.eventId
            }
            fetchAllKpis[_rpId] = call(getKpisResponsePlans, params)
        })

    const listKpis = yield all(fetchAllKpis)
    yield put(storeRPKpis(listKpis))
    yield put(setFetchingRPKpis(false))
}

function* kpisResponsePlansSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield takeLatest(loadRPKpi, fetchRPKpis)])
}

export default kpisResponsePlansSaga
