import { all, AllEffect, call, CallEffect, ForkEffect, put, select, takeLatest } from 'redux-saga/effects'
import { AxiosError, AxiosResponse } from 'axios'
import {
    getAverageCleanDataRequest,
    getAveragePatternDataRequest,
    getPredictionVsRealRequest,
    getQMDMDataRequest,
    getVariabilityPatternDataRequest,
} from '../../../services/qualityManager/qualityManagerService'
import {
    storeQmData,
    setLoadingQmData,
    getSimulatedVsRealQuality,
    getQMDMData,
    getQMDMAverages,
    getQMDMVariability,
    setQmError,
} from './qualityManagerDialogSlice'
import { QmOutputTypes } from '../qualityManagerDialogOptions'
import { selectAvailablePatterns } from '../../pattern/store/patternSlice'

function* fetchSimulatedVsReal(action: { payload: ISimulatedVsRealQualityAction }): Generator<unknown, any, any> {
    try {
        const { visualisation, variable, from, to } = action.payload
        const outputOptions: IQMOutputType | undefined = QmOutputTypes.get(variable)
        if (outputOptions) {
            const params = {
                type: visualisation,
                variable: outputOptions.variable,
                statistic: outputOptions.statistic,
                object_type: outputOptions.object_type,
                from,
                to,
            }
            const qmData: IPredVsRealResponse = yield call(getPredictionVsRealRequest, params)
            yield put(storeQmData(qmData))
            yield put(setLoadingQmData(false))
        }
    } catch (err) {
        const error = err as Error | AxiosError
        yield put(setQmError(error.message))
        console.error('fetchSimulatedVsReal error:', error)
    }
}

function* fetchQMDMData(action: { payload: IQMDMDataAction }): Generator<unknown, any, any> {
    try {
        const { visualisation, variable, source, interval, from, to, filter } = action.payload
        const qmData: IQMDMResponse = yield call(getQMDMDataRequest, {
            type: visualisation,
            variable,
            realm: source,
            interval,
            from,
            to,
            filter,
        })
        yield put(storeQmData(qmData))
        yield put(setLoadingQmData(false))
    } catch (err) {
        const error = err as Error | AxiosError
        yield put(setQmError(error.message))
        console.error('fetchQMDMData error:', error)
    }
}

function* fetchAverages(action: { payload: IQMDMAverages }): Generator<unknown, any, any> {
    try {
        const { variable, t, n_hours } = action.payload
        const _availablePatterns: IPatternJson[] = yield select(selectAvailablePatterns)
        let fetch: { [p: string]: CallEffect<AxiosResponse<IQMAverageResponse, any>> } = {}

        _availablePatterns.forEach(pattern => {
            fetch[`Pattern ${pattern.pattern_id}`] = call(getAveragePatternDataRequest, {
                pattern_id: pattern.pattern_id,
                variable,
                t,
                n_hours,
            })
        })
        fetch['PM pred'] = call(getAveragePatternDataRequest, {
            variable,
            t,
            n_hours,
        })
        fetch['Clean'] = yield call(getAverageCleanDataRequest, {
            variable,
            t,
            n_hours,
        })
        const qmDatafetch = yield all(fetch)
        let qmData: any[] = []
        for (const [k, v] of Object.entries(qmDatafetch)) {
            if (v) {
                const items = v as IQMAverage[]
                items.forEach(item => {
                    if (qmData[item.time]) {
                        qmData[item.time] = qmData[item.time].concat({ key: k, avg: item.avg })
                    } else {
                        qmData[item.time] = [{ key: k, avg: item.avg }]
                    }
                })
            }
        }
        // if (qmDatafetch['Clean'].length !== qmDatafetch[`Pattern ${_availablePatterns?.[0].pattern_id}`].length) {
        //     let qmDataFiltered: any[] = []
        //     for (const v of qmDatafetch['Clean']) {
        //         const item = v as IQMAverage
        //         qmDataFiltered[item.time] = qmData[item.time]
        //     }
        //     qmData = qmDataFiltered
        // }

        //qmData.filter(item => item)
        yield put(storeQmData(qmData))
        yield put(setLoadingQmData(false))
    } catch (err) {
        const error = err as Error | AxiosError
        yield put(setQmError(error.message))
        console.error('fetchQMDMData error:', error)
    }
}

function* fetchPatternVariability(action: { payload: IQMDMVariability }): Generator<unknown, any, any> {
    try {
        const { n_items, t } = action.payload
        const _availablePatterns: IPatternJson[] = yield select(selectAvailablePatterns)

        let fetch: { [p: string]: CallEffect<AxiosResponse<IQMVariabilityResponse, any>> } = {}

        _availablePatterns.forEach(pattern => {
            fetch[`Pattern ${pattern.pattern_id}`] = call(getVariabilityPatternDataRequest, {
                pattern_id: pattern.pattern_id,
                t,
                n_items,
            })
        })
        const qmDatafetch = yield all(fetch)
        let qmData: any[] = []
        for (const [k, v] of Object.entries(qmDatafetch)) {
            if (v) {
                const items = v as IQMVariability[]
                items.forEach(item => {
                    if (qmData[item.time]) {
                        qmData[item.time] = qmData[item.time].concat({ key: k, reliability: item.reliability })
                    } else {
                        qmData[item.time] = [{ key: k, reliability: item.reliability }]
                    }
                })
            }
        }

        yield put(storeQmData(qmData))
        yield put(setLoadingQmData(false))
    } catch (err) {
        const error = err as Error | AxiosError
        yield put(setQmError(error.message))
        console.error('fetchQMDMData error:', error)
    }
}

function* qualityManagerDialogSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([
        yield takeLatest(getSimulatedVsRealQuality, fetchSimulatedVsReal),
        yield takeLatest(getQMDMData, fetchQMDMData),
        yield takeLatest(getQMDMAverages, fetchAverages),
        yield takeLatest(getQMDMVariability, fetchPatternVariability),
    ])
}

export default qualityManagerDialogSaga
