import { DateTime } from 'luxon'
import Chart from 'react-apexcharts'
import { CloseRounded } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import { useState, useEffect, FC, SetStateAction, Dispatch } from 'react'
import { useSelector } from 'react-redux'
import { selectConfig, selectMainConfig, selectTimeZone } from '../core/coreSlice'
import {
    heatmapOptionsInitialState,
    HEATMAP_DAYS,
    lineChartOptionsInitialState,
    loadLineChartInitialStateWithTooltip,
    viewModes,
} from './qualityManagerDialogOptions'
import {
    QmDialogCloseButton,
    QmDialogErrorTitleTypography,
    QmDialogErrorTypography,
    QmDialogLoaderContainer,
    QmDialogTab,
    QmDialogTabs,
    QmDialogTabsBox,
    QmDialogTitleTypography,
    QmPagination,
} from './qualityManagerDialogStyles'
import {
    deployHorizons,
    generateColorScaleForPatterns,
    generateDMDataPatternsChartSeries,
    generateHeatmapSeries,
    generateLineChartSeries,
    generatePatternsAvailableLineChartSeries,
    generatePatternsSelectedLineChartSeries,
    getSeriesName,
    isDateWithinRange,
    refreshHeatmap,
    refreshLineChart,
} from './qualityManagerDialogHelper'
import { selectAvailablePatterns } from '../pattern/store/patternSlice'
import { changeDataByMap } from '../viewSettings/viewSettingsSlice'
import {
    getQMDMAverages,
    getQMDMData,
    getQMDMVariability,
    getSimulatedVsRealQuality,
    selectLoadingQmData,
    selectQmData,
    selectQmError,
} from './store/qualityManagerDialogSlice'
import { QualityManagerMap } from './qualityManagerDialogMap'
import QualityManagerDialogPickers from './qualityManagerDialogPickers'
import { LOADER_COLOR } from '../../theme'
import { GridLoader } from 'react-spinners'
import QualityManagerDialogTableSwitch from './qualityManagerDialogTable/qualityManagerDialogTableSwitch'
import { useAppDispatch } from '../../app/hooks'
import { AnyAction } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'

export const QualityManagerDialog: FC<IQualityManagerDialogProps> = ({ open, epoch, options, onClose }) => {
    const { t } = useTranslation()
    const _mainConfig: IMainConfig = useSelector(selectMainConfig)
    const _moduleConfig: IModuleConfig = useSelector(selectConfig)
    const _timeZone: string = useSelector(selectTimeZone)

    const { time: timeFormat, date: dateFormat } = _moduleConfig.date_format

    const _availablePatterns: IPatternJson[] = useSelector(selectAvailablePatterns)
    const _isLoading: boolean = useSelector(selectLoadingQmData)
    const _qmData: any = useSelector(selectQmData)
    const _qmError: any = useSelector(selectQmError)

    const [dataSize, setDataSize] = useState<number>(0)
    const [heatmapSeries, setHeatmapSeries] = useState<any[]>([])
    const [lineChartSeries, setLineChartSeries] = useState<any[]>([])

    const [visualisation, setVisualisation] = useState<string>(
        options.visualisation.types?.[0] ? options.visualisation.types[0] : ''
    )
    const [outputSelected, setOutputSelected] = useState<string>(
        options.visualisation.qm_output?.[0] && !options.visualisation.types?.[0].startsWith('p')
            ? options.visualisation.qm_output[0]
            : options.visualisation.variables?.[0]
            ? options.visualisation.variables[0]
            : ''
    )
    const [sourceSelected, setSourceSelected] = useState<string>(
        options.visualisation.source && options.visualisation.source[0] ? options.visualisation.source[0] : ''
    )
    const [intervalSelected, setIntervalSelected] = useState<string>(
        options.visualisation.interval && options.visualisation.interval[0] ? options.visualisation.interval[0] : ''
    )
    const [filterSelected, setFilterSelected] = useState<number>(100)
    const [numberItemsToShow, setNumberItemsToShow] = useState<number>(10)

    const [disabled, setDisabled] = useState<boolean>(false)
    const [displayLoader, setDisplayLoader] = useState(true)
    const [disableFutureNavigation, setDisableFutureNavigation] = useState<boolean>(true)

    const [viewMode, setViewMode] = useState<number>(0)
    const [numberPages, setNumberPages] = useState<number>(1)
    const [page, setPage] = useState<number>(1)
    const [showPagination, setShowPagination] = useState<boolean>(false)
    const [patternScale, setPatternScale] = useState<IQualityManagerPatternScale[]>([])

    const [horizon, setHorizon] = useState<number>(0)
    const [horizons, setHorizons] = useState<IQualityManagerHorizon[]>([])
    const [clockInterval, setClockInterval] = useState<number>(5)
    const [horizonStep, setHorizonStep] = useState<number>(15)
    const [predictionHorizon, setPredictionHorizon] = useState<number>(3600000)

    const [targetDate, setTargetDate] = useState<DateTime>(DateTime.now())
    const [fromDate, setFromDate] = useState<DateTime>(DateTime.now())
    const [oneDayDate, setOneDayDate] = useState<DateTime>(DateTime.now())
    const [lastAvailableDay, setLastAvailableDay] = useState<DateTime>(DateTime.now())

    const [heatmapOptions, setHeatmapOptions] = useState<ApexCharts.ApexOptions>(heatmapOptionsInitialState)
    const [lineChartOptions, setLineChartOptions] = useState<ApexCharts.ApexOptions>(lineChartOptionsInitialState)

    const [chartContainerHeight, setChartContainerHeight] = useState('640')
    const [componentReady, setComponentReady] = useState(false)

    const dispatch: Dispatch<AnyAction> = useAppDispatch()

    useEffect(() => {
        const _lastAvailableDayEndDate: DateTime = DateTime.fromMillis(epoch, { zone: _timeZone }).endOf('day')
        const _fromDateStartDay: DateTime = _lastAvailableDayEndDate.minus({ days: HEATMAP_DAYS })

        const nextHorizonCount: number = _moduleConfig['horizon-count']
        const nextHorizonStep: number = _moduleConfig['horizon-step']
        const clockInterval: number = Number.parseInt(_mainConfig.defaults['clock-interval'])
        const size: number = 86400 / clockInterval
        const ph: number = nextHorizonStep * nextHorizonCount * 60000

        const nextTargetDate = _fromDateStartDay.plus({ days: HEATMAP_DAYS })

        setClockInterval(clockInterval)
        setHorizonStep(nextHorizonStep)
        setPredictionHorizon(ph)
        setHorizon(0)
        setDataSize(size)
        setLastAvailableDay(_lastAvailableDayEndDate)
        setOneDayDate(_lastAvailableDayEndDate)
        setFromDate(_fromDateStartDay)
        setTargetDate(nextTargetDate)
        setHeightChart(275)
        setHorizons(deployHorizons(visualisation, nextHorizonCount, nextHorizonStep))
        setPatternScale(generateColorScaleForPatterns(_availablePatterns))
        loadLineChartInitialStateWithTooltip(_timeZone, dateFormat, timeFormat, setLineChartOptions)
        //adds another map to the container to avoid overlaps with the monitor map which is actually active
        dispatch(changeDataByMap(2))

        setComponentReady(true)
        return () => {
            dispatch(changeDataByMap(1))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setDisabled(_isLoading)
        if (!_isLoading) setTimeout(() => setDisplayLoader(_isLoading), 3000)
        else setDisplayLoader(_isLoading)
    }, [_isLoading])

    useEffect(() => {
        if (!_isLoading && componentReady) {
            getData()
            refreshData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        outputSelected,
        sourceSelected,
        intervalSelected,
        filterSelected,
        visualisation,
        targetDate,
        oneDayDate,
        numberItemsToShow,
    ])

    useEffect(() => {
        if (componentReady) {
            if (visualisation === 'realVsPredicted') {
                // Analytical prediction
                setHorizons(
                    deployHorizons(visualisation, _moduleConfig['horizon-count'], _moduleConfig['horizon-step'])
                )
            } else if (visualisation === 'simulatedVsReal') {
                // Simulated prediction
                setHorizons(
                    deployHorizons(visualisation, _moduleConfig['horizon-count'], _moduleConfig['horizon-step'])
                )
            } else if (visualisation === 'percentageMissingData' || visualisation.includes('patterns')) {
                setHorizons(deployHorizons(visualisation, 0, 0))
            }
            // Heatmap
            if (viewMode === 0) {
                regenerateHeatmapSeries()
            }

            // Chart
            if (viewMode === 2) {
                regenerateLineChartSeries()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_qmData])

    useEffect(() => {
        if (!_isLoading && componentReady) {
            refreshData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewMode, lineChartSeries])

    useEffect(() => {
        if (!_isLoading && componentReady) {
            const nextTargetDate = fromDate.plus({ days: HEATMAP_DAYS })
            setTargetDate(nextTargetDate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate])

    const regenerateHeatmapSeries = (h: number = horizon, pageToChange: number = page) => {
        generateHeatmapSeries(
            _qmData,
            h,
            dataSize,
            pageToChange,
            _timeZone,
            dateFormat,
            timeFormat,
            outputSelected,
            intervalSelected,
            visualisation,
            clockInterval,
            setPage,
            setNumberPages,
            setHeatmapSeries
        )
    }

    const regenerateLineChartSeries = () => {
        if (visualisation === 'patternsVsCleanData' || visualisation === 'patternsVariability') {
            generateDMDataPatternsChartSeries(_qmData, epoch, setLineChartSeries)
        } else if (visualisation === 'patternsAvailableVsRealData') {
            generatePatternsAvailableLineChartSeries(_qmData, epoch, _availablePatterns, setLineChartSeries)
        } else if (visualisation === 'patternsSelectedVsRealData') {
            generatePatternsSelectedLineChartSeries(_qmData, epoch, setLineChartSeries)
        } else {
            generateLineChartSeries(
                _qmData,
                epoch,
                horizon,
                horizonStep,
                outputSelected,
                visualisation,
                setLineChartSeries
            )
        }
    }

    const refreshData = () => {
        switch (viewMode) {
            case 0:
                refreshHeatmap(
                    visualisation,
                    outputSelected,
                    intervalSelected,
                    patternScale,
                    heatmapOptionsInitialState,
                    setHeatmapOptions
                )
                regenerateHeatmapSeries()
                break
            case 2:
                refreshLineChart(
                    visualisation,
                    outputSelected,
                    _timeZone,
                    dateFormat,
                    timeFormat,
                    lineChartOptionsInitialState,
                    setLineChartOptions
                )
                break
            default:
                break
        }
    }

    const setHeightChart = (heightLess: number) => {
        if (window && window.innerHeight) {
            const _chartContainerHeight: number = window.innerHeight - heightLess
            setChartContainerHeight(_chartContainerHeight.toString())
        }
    }

    const handleClose = () => {
        onClose(open)
    }

    const handleTabsChange = (_event: any, value: SetStateAction<number>) => {
        setViewMode(value)
        if (value === 0 && heatmapSeries.length === 0) {
            regenerateHeatmapSeries()
        }
        if (value === 2) {
            regenerateLineChartSeries()
        }
    }

    const handleHorizonChange = (_event: any, value: number) => {
        setHorizon(value)
        if (viewMode === 0) {
            regenerateHeatmapSeries(value)
        }
        if (viewMode === 2) {
            const nextLineChartOptions = lineChartOptions
            nextLineChartOptions.yaxis = {
                title: {
                    text: getSeriesName(horizon, horizonStep, visualisation),
                    rotate: 90,
                },
            }
            setLineChartOptions(nextLineChartOptions)
            generateLineChartSeries(
                _qmData,
                epoch,
                value,
                horizonStep,
                outputSelected,
                visualisation,
                setLineChartSeries
            )
        }
    }

    const loadData = (pageToChange: number) => {
        regenerateHeatmapSeries(horizon, pageToChange)
    }

    const handleVisualisationChange = async (event: { value: string }) => {
        const value: string = event.value
        setShowPagination(false)
        setPage(1)
        setHeightChart(275)
        setOutputSelected('flowQmStations')
        setIntervalSelected('date')
        switch (value) {
            case 'realVsPredicted':
            case 'simulatedVsReal':
                setViewMode(0)
                break
            case 'percentageMissingData':
                setViewMode(0)
                setOutputSelected('flow')
                break
            case 'patterns':
                setViewMode(0)
                break
            case 'patternsSelectedVsRealData':
                setViewMode(0)
                break
            case 'patternsAvailableVsRealData':
                setViewMode(2)
                setOutputSelected('qm')
                break
            case 'patternsVariability':
            case 'patternsVsCleanData':
                setViewMode(2)
                setOutputSelected('flow')
                break
            default:
                break
        }
        setVisualisation(value)
    }

    const handleOutputChange = (event: { value: string }) => {
        setOutputSelected(event.value)
    }

    const handleSourceChange = (event: { value: string }) => {
        setSourceSelected(event.value)
    }

    const handleIntervalChange = (event: { value: string }) => {
        const value: string = event.value
        if (value) {
            setIntervalSelected(value)
            if (value === 'date') {
                setShowPagination(false)
                setHeightChart(275)
            } else {
                _qmData && setShowPagination(true)
                setHeightChart(320)
            }
        }
    }

    const handleFilterChange = (event: { target: { value: string | number } }) => {
        const value: number = +event.target.value
        if (!isNaN(value)) {
            setFilterSelected(value)
        }
    }

    const handleItemsToShowChange = (event: { target: { value: string | number } }) => {
        const value: number = +event.target.value
        if (!isNaN(value)) {
            setNumberItemsToShow(value)
        }
    }

    const loadPrevious = (intervalType: 'week' | 'oneDay', days: number) => {
        if (!disabled) {
            const nextDate: DateTime = intervalType === 'week' ? fromDate : oneDayDate
            const nextFromDate: DateTime = nextDate.minus({ days })
            if (intervalType === 'week') {
                setFromDate(nextFromDate)
            } else {
                setOneDayDate(nextFromDate)
            }
            setDisableFutureNavigation(false)
        }
    }

    const isWithinRange = (date: DateTime): boolean => {
        const maxDate = lastAvailableDay.startOf('day')
        return !disableFutureNavigation && date.startOf('day') < maxDate
    }

    const loadNext = (intervalType: 'week' | 'oneDay', days: number) => {
        const nextDate: DateTime = intervalType === 'week' ? fromDate : oneDayDate
        const nextFromDate: DateTime = nextDate.plus({ days })
        if (intervalType === 'week') {
            const nextTargetDate: DateTime = nextFromDate.plus({ days: HEATMAP_DAYS })
            if (isWithinRange(nextTargetDate)) {
                setFromDate(nextFromDate)
            } else {
                loadLastPage()
            }
        } else {
            if (isWithinRange(nextFromDate)) {
                setOneDayDate(nextFromDate)
            } else {
                loadLastPageOneDay()
            }
        }
    }

    const loadLastPage = () => {
        if (!disableFutureNavigation) {
            const _fromDate: any = lastAvailableDay.minus({ days: HEATMAP_DAYS })
            setFromDate(_fromDate)
            setDisableFutureNavigation(true)
        }
    }

    const loadLastPageOneDay = () => {
        if (!disableFutureNavigation) {
            const nextDate: DateTime = DateTime.fromMillis(epoch, { zone: _timeZone }).endOf('day')
            setOneDayDate(nextDate)
            setDisableFutureNavigation(true)
        }
    }

    const handleFromDatePickerSelection = (dateSelected: any) => {
        const nextTargetDate: DateTime = dateSelected.startOf('day').plus({ days: HEATMAP_DAYS })

        if (isDateWithinRange(nextTargetDate, lastAvailableDay)) {
            setDisableFutureNavigation(nextTargetDate.hasSame(lastAvailableDay, 'day'))
            setFromDate(dateSelected.startOf('day'))
        }
    }

    const handleTargetDatePickerSelection = (dateSelected: any) => {
        const nextFromDate: DateTime = dateSelected.endOf('day').minus({ days: HEATMAP_DAYS })

        if (isDateWithinRange(dateSelected, lastAvailableDay)) {
            setDisableFutureNavigation(dateSelected.hasSame(lastAvailableDay, 'day'))
            setFromDate(nextFromDate)
        }
    }

    const handleOneDayDatePickerSelection = (dateSelected: any) => {
        const nextTargetDate: DateTime = dateSelected.endOf('day')

        if (nextTargetDate.hasSame(lastAvailableDay, 'day')) {
            setDisableFutureNavigation(true)
        } else if (isDateWithinRange(nextTargetDate, lastAvailableDay)) {
            setDisableFutureNavigation(false)
        } else {
            loadLastPage()
            return
        }

        setOneDayDate(dateSelected.startOf('day'))
    }

    const getData = () => {
        const variable = outputSelected.toLowerCase()
        switch (visualisation) {
            case 'simulatedVsReal':
            case 'realVsPredicted':
                dispatch(
                    getSimulatedVsRealQuality({
                        visualisation: visualisation === 'simulatedVsReal' ? 'sim' : 'ana',
                        variable: variable,
                        from: fromDate.startOf('day').toMillis(),
                        to: targetDate.startOf('day').plus({ days: 1 }).toMillis(),
                    })
                )
                break
            case 'percentageMissingData':
            case 'patterns':
            case 'patternsSelectedVsRealData':
            case 'patternsAvailableVsRealData':
                dispatch(
                    getQMDMData({
                        visualisation: visualisation === 'percentageMissingData' ? 'missing' : 'pattern',
                        variable: variable,
                        source: sourceSelected,
                        interval: intervalSelected,
                        from: fromDate.startOf('day').toMillis(),
                        to: targetDate.startOf('day').plus({ days: 1 }).toMillis(),
                        filter: filterSelected,
                    })
                )
                break
            case 'patternsVsCleanData':
                dispatch(
                    getQMDMAverages({
                        variable: variable,
                        t: oneDayDate.toMillis(),
                        n_hours: 24,
                    })
                )
                break
            case 'patternsVariability':
                dispatch(getQMDMVariability({ t: oneDayDate.toMillis() + 1, n_items: numberItemsToShow }))
                break
            default:
                return
        }
    }

    const renderTabs = () => {
        return viewModes.map(({ label }: IQualityManagerDialogViewMode, $i: number) => (
            <QmDialogTab
                style={{
                    display:
                        (visualisation === 'patternsAvailableVsRealData' ||
                            visualisation === 'patternsVsCleanData' ||
                            visualisation === 'patternsVariability') &&
                        ($i === 3 || $i === 0)
                            ? 'none'
                            : visualisation === 'percentageMissingData' && ($i === 3 || $i === 2)
                            ? 'none'
                            : visualisation === 'patterns' && ($i === 3 || $i === 1 || $i === 2)
                            ? 'none'
                            : 'block',
                }}
                label={t(`qualityManager.${label}`)}
                key={$i}
            />
        ))
    }
    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} fullScreen={true}>
            <DialogTitle {...({ component: 'div' } as any)}>
                <QmDialogTitleTypography variant='h6'>{t('qualityManager.qualityManager')}</QmDialogTitleTypography>
                <QmDialogCloseButton onClick={handleClose}>
                    <CloseRounded />
                </QmDialogCloseButton>
            </DialogTitle>
            {_qmError.length > 0 ? (
                <DialogContent>
                    <QmDialogErrorTitleTypography variant='h6'>
                        {t('qualityManager.somethingWrong')}
                    </QmDialogErrorTitleTypography>
                    <QmDialogErrorTypography variant='h5'>
                        {t('qualityManager.errorLoadingData')}
                    </QmDialogErrorTypography>
                    <QmDialogErrorTypography variant='h5'>{_qmError}</QmDialogErrorTypography>
                    <QmDialogErrorTypography variant='h5'>
                        {t('qualityManager.problemPersists')}
                    </QmDialogErrorTypography>
                </DialogContent>
            ) : (
                <DialogContent>
                    <QmDialogTabsBox sx={displayLoader ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
                        <QmDialogTabs
                            value={viewMode}
                            onChange={handleTabsChange}
                            aria-label='view mode tabs'
                            textColor='inherit'
                            TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}>
                            {renderTabs()}
                        </QmDialogTabs>
                    </QmDialogTabsBox>
                    <QualityManagerDialogPickers
                        options={options.visualisation}
                        viewMode={viewMode}
                        horizon={horizon}
                        horizons={horizons}
                        isLoading={displayLoader}
                        visualisation={visualisation}
                        outputSelected={outputSelected}
                        sourceSelected={sourceSelected}
                        intervalSelected={intervalSelected}
                        filterSelected={filterSelected}
                        numberItemsToShow={numberItemsToShow}
                        disableFutureNavigation={disableFutureNavigation}
                        disabled={disabled}
                        oneDayDate={oneDayDate}
                        fromDate={fromDate}
                        targetDate={targetDate}
                        handleVisualisationChange={handleVisualisationChange}
                        handleOutputChange={handleOutputChange}
                        handleSourceChange={handleSourceChange}
                        handleIntervalChange={handleIntervalChange}
                        handleFilterChange={handleFilterChange}
                        handleItemsToShowChange={handleItemsToShowChange}
                        handleTabsChange={handleHorizonChange}
                        loadPreviousWeek={() => {
                            loadPrevious('week', HEATMAP_DAYS)
                        }}
                        loadPreviousDay={() => {
                            loadPrevious('week', 1)
                        }}
                        loadNextWeek={() => {
                            loadNext('week', HEATMAP_DAYS)
                        }}
                        loadNextDay={() => {
                            loadNext('week', 1)
                        }}
                        loadLastPage={loadLastPage}
                        handleFromDatePickerChange={handleFromDatePickerSelection}
                        handleTargetDatePickerChange={handleTargetDatePickerSelection}
                        loadPreviousWeekOneDay={() => {
                            loadPrevious('oneDay', HEATMAP_DAYS)
                        }}
                        loadPreviousOneDay={() => {
                            loadPrevious('oneDay', 1)
                        }}
                        loadNextWeekOneDay={() => {
                            loadNext('oneDay', HEATMAP_DAYS)
                        }}
                        loadNextOneDay={() => {
                            loadNext('oneDay', 1)
                        }}
                        loadLastPageOneDay={loadLastPageOneDay}
                        handleOneDayDatePickerChange={handleOneDayDatePickerSelection}
                    />
                    {displayLoader && (
                        <Grid item>
                            <QmDialogLoaderContainer className='qm__loader-container'>
                                <GridLoader size={22} margin={7} color={LOADER_COLOR} />
                            </QmDialogLoaderContainer>
                        </Grid>
                    )}
                    {viewMode === 1 && !displayLoader && (
                        <QualityManagerDialogTableSwitch
                            visualisation={visualisation}
                            intervalSelected={intervalSelected}
                            qmData={_qmData}
                            horizons={horizons}
                            predictionHorizon={predictionHorizon}
                        />
                    )}
                    {viewMode === 0 && !displayLoader && (
                        <Grid item>
                            <Chart
                                options={heatmapOptions}
                                type='heatmap'
                                series={heatmapSeries}
                                width='100%'
                                height={chartContainerHeight}
                            />
                        </Grid>
                    )}
                    {viewMode === 2 && !displayLoader && (
                        <Grid item>
                            <Chart
                                options={lineChartOptions}
                                type='line'
                                series={lineChartSeries}
                                width='100%'
                                height={chartContainerHeight}
                            />
                        </Grid>
                    )}
                    {viewMode === 3 && !displayLoader && (
                        <Grid item>
                            <QualityManagerMap epoch={epoch} options={options} />
                        </Grid>
                    )}
                    {_qmData && showPagination && viewMode === 0 && !displayLoader && (
                        <QmPagination
                            count={numberPages}
                            page={page}
                            onChange={(event, page) => loadData(page)}
                            shape='rounded'
                        />
                    )}
                </DialogContent>
            )}
        </Dialog>
    )
}
