import { TableHead, TableRow, TableCell, TableSortLabel, Box } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { QmDialogTableTypographyHeader } from '../qualityManagerDialogStyles'
import { useTranslation } from 'react-i18next'

const QualityManagerDialogTableHead: React.FC<IQualityManagerDialogTableHeadProps> = ({
    order,
    orderBy,
    onRequestSort,
    horizons,
}: IQualityManagerDialogTableHeadProps) => {
    const { t } = useTranslation()
    const TypoValue = ({ sortValue }: { sortValue: string }) => {
        return (
            <QmDialogTableTypographyHeader>
                {sortValue === t('qualityManager.global') || sortValue === t('qualityManager.time') ? sortValue : `t +${sortValue}`}
            </QmDialogTableTypographyHeader>
        )
    }

    const HeaderCell = ({
        id,
        align,
    }: {
        id: string
        align: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined
    }) => {
        return (
            <TableCell key={id} align={align} sortDirection={orderBy === id ? order : false}>
                <TableSortLabel
                    active={orderBy === id}
                    direction={orderBy === id ? order : 'asc'}
                    onClick={createSortHandler(id)}>
                    <TypoValue sortValue={id} />
                    {orderBy === id ? (
                        <Box component='span' sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                    ) : null}
                </TableSortLabel>
            </TableCell>
        )
    }

    const createSortHandler = (property: string) => (event: any) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                <HeaderCell id={t('qualityManager.time')} align='left' />
                {horizons &&
                    horizons.map(({ value }: IQualityManagerHorizon, index: number) => (
                        <HeaderCell key={index} id={value} align='center' />
                    ))}
            </TableRow>
        </TableHead>
    )
}

export default QualityManagerDialogTableHead
