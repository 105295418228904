import * as React from 'react'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/CloseRounded'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {
    Dialog,
    DialogContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import { DateTime, Duration } from 'luxon'
import { useTranslation } from 'react-i18next'
import { getTasks } from '../../../../services/tasks/tasksService'
import { getEvents } from '../../../../services/events/eventsService'
import { getEvaluations } from '../../../../services/evaluations/evaluationsService'
import SearchTermComponent from '../../../../components/searchTermComponent/SearchTermComponent'
import { SearchTermWrapper } from '../../../analyzeContainer/components/kpi/kpis/kpiDialog/KpiDialogStyles'
import { ProgressField } from './ProgressFiled'
import { getResponsePlanInfo, statusTypes } from './TaskDialogHelper'
import { selectConfig, selectTimeZone } from '../../../../features/core/coreSlice'
import { CloseButton, Title } from './TaskDialogStyles'
import { humanizeDuration } from '../../../../helpers/DateTimeHelper'

export const TasksDialog: React.FC<ISystemStatusDialogProps> = ({ open, onClose }: ISystemStatusDialogProps) => {
    const { t } = useTranslation()
    const _config: IModuleConfig = useSelector(selectConfig)
    const _timeZone: string = useSelector(selectTimeZone)

    const [searchTerm, setSearchTerm] = useState<string>('')
    const [events, setEvents] = useState<IEventNetwork[]>([])
    const [evaluations, setEvaluations] = useState<IEvaluationStatic[]>([])
    const [tasks, setTasks] = useState<ITask[]>([])
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout>()

    const { time: timeFormat, date: dateFormat, timeWSeconds: timeWSecondsFormat } = _config.date_format

    useEffect(() => {
        if (open) {
            getEvents().then((payload: any) => {
                const nextEvents: IEventNetwork[] = payload
                if (events !== nextEvents) {
                    setEvents(nextEvents)
                }
            })
            getEvaluations().then((payload: any) => {
                const nextEvaluations: IEvaluationStatic[] = payload
                if (evaluations !== nextEvaluations) {
                    setEvaluations(nextEvaluations)
                }
            })
            getTasks()
                .then((payload: any) => {
                    const tasks: ITask[] = payload
                    setTasks(tasks)
                })
                .catch(err => console.log(err))
            setIntervalId(
                setInterval(
                    () =>
                        getTasks()
                            .then((payload: any) => {
                                const tasks: ITask[] = payload
                                setTasks(tasks)
                            })
                            .catch(err => console.log(err)),
                    5000
                )
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const handleClose = () => {
        clearInterval(intervalId)
        setIntervalId(undefined)
        setSearchTerm('')
        onClose(open)
    }

    const searchTermChange = (searchTerm: string) => {
        if (searchTerm.length > 1 || searchTerm === '') {
            setSearchTerm(searchTerm)
        }
    }

    const getEventInfo = (task: ITask): string => {
        const event = events?.find((event: IEventNetwork) => Number(event.eid) === Number(task.event_id))
        if (event) {
            const eventId = event.eid || event.id
            return `${eventId} - ${event.description}`
        } else {
            return task.event_id ? task.event_id : ''
        }
    }

    const getTaskName = (task: ITask): string => {
        const evaluation = evaluations.filter(eva => eva.epoch === +task.pack_id).pop()
        return evaluation ? `${evaluation.name}` : task.name
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby='system-status-dialog-title'
            open={open}
            fullWidth={true}
            fullScreen={true}
            id='systemStatusDialog'>
            <Title variant='h2'>{t('tasks.tasks')}</Title>
            <CloseButton onClick={handleClose}>
                <CloseIcon />
            </CloseButton>
            <DialogContent id='systemStatusDialogContent' className='table__container'>
                <SearchTermWrapper>
                    <SearchTermComponent
                        searchTermChange={searchTermChange}
                        iconColor='inherit'
                        startAdornment={true}
                        placeholder={'Search ...'}
                        disableUnderline={false}
                    />
                </SearchTermWrapper>
                <Table aria-label='Response Plans Evaluations list' key='responsePlansTableView'>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>
                                <Typography>{t('tasks.date')}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{t('tasks.name')}</Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Typography>{t('tasks.event')}</Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Typography>{t('tasks.rp')}</Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Typography>{t('tasks.progress')}</Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Typography>{t('tasks.waiting_time')}</Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Typography>{t('tasks.duration')}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className='table__body'>
                        {tasks
                            ?.filter(task => {
                                const taskDateTime = DateTime.fromMillis(Number.parseInt(task.pack_id), {
                                    zone: _timeZone,
                                })
                                const date = taskDateTime.toFormat(dateFormat)
                                const time = taskDateTime.toFormat(timeFormat)
                                const responsePlanInfo = getResponsePlanInfo(task) || 'unknown'
                                const eventInfo = getEventInfo(task) || 'unknown'
                                const taskName = getTaskName(task) || 'unknown'
                                const executionTime = DateTime.fromMillis(task.execution_time, {
                                    zone: _timeZone,
                                }).toFormat(timeWSecondsFormat)
                                const waitingTimeDuration = Duration.fromMillis(task.waiting_time)
                                const waitingTime = humanizeDuration(waitingTimeDuration, {
                                    includeSeconds: true,
                                })
                                const simulationDuration = Duration.fromMillis(task.simulation_duration)
                                const duration = humanizeDuration(simulationDuration, {
                                    includeSeconds: true,
                                })

                                if (
                                    (task.status !== 0 &&
                                        (date.includes(searchTerm) ||
                                            task.name.includes(searchTerm) ||
                                            time.includes(searchTerm))) ||
                                    task.event_id?.includes(searchTerm) ||
                                    statusTypes.get(task.status)?.toUpperCase().includes(searchTerm.toUpperCase()) ||
                                    task.pack_id.includes(searchTerm) ||
                                    responsePlanInfo.includes(searchTerm) ||
                                    eventInfo.includes(searchTerm) ||
                                    executionTime.includes(searchTerm) ||
                                    taskName.includes(searchTerm) ||
                                    waitingTime.includes(searchTerm) ||
                                    duration.includes(searchTerm)
                                ) {
                                    return task
                                }
                                return null
                            })
                            .map((task, index) => {
                                const taskDateTime = DateTime.fromMillis(Number.parseInt(task.pack_id), {
                                    zone: _timeZone,
                                })
                                const date = taskDateTime.toFormat(dateFormat)
                                const time = taskDateTime.toFormat(timeFormat)
                                const responsePlanInfo = getResponsePlanInfo(task) || 'unknown'
                                const eventInfo = getEventInfo(task) || 'unknown'
                                const taskName = getTaskName(task) || 'unknown'
                                const waitingTimeDuration = Duration.fromMillis(task.waiting_time)
                                const waitingTime = humanizeDuration(waitingTimeDuration, {
                                    includeSeconds: true,
                                })
                                const simulationDuration = Duration.fromMillis(task.simulation_duration)
                                const duration = humanizeDuration(simulationDuration, { includeSeconds: true })

                                return (
                                    <TableRow
                                        key={task.id}
                                        className={index % 2 === 0 ? 'table__row--even' : 'table__row--odd'}>
                                        <TableCell align='center' key={task.execution_time}>
                                            <Grid
                                                container
                                                direction='column'
                                                alignContent='center'
                                                alignItems='flex-start'
                                                style={{ minWidth: '80px' }}>
                                                <Grid item>
                                                    <Typography variant='body1' className='smaller'>
                                                        {date}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='body1' className='smaller'>
                                                        {time}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell align='left' key={task.name}>
                                            <Typography variant='body1'>{taskName}</Typography>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Typography variant='body1'>{eventInfo}</Typography>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Typography variant='body1'>{responsePlanInfo}</Typography>
                                        </TableCell>
                                        <TableCell align='center'>
                                            {task.error && (
                                                <Typography variant='body1' className='smaller' color='error'>
                                                    Failed
                                                </Typography>
                                            )}
                                            {!task.error && (
                                                <ProgressField
                                                    progress={task.percentage}
                                                    status={task.status}></ProgressField>
                                            )}
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Typography variant='body1' className='smaller'>
                                                {waitingTime}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Typography variant='body1' className='smaller'>
                                                {duration}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='left'>
                                            {task.error && (
                                                <Tooltip title={task.error} aria-label={task.error}>
                                                    <ErrorIcon color='error'></ErrorIcon>
                                                </Tooltip>
                                            )}
                                            {!task.error && task.percentage === 100 && (
                                                <CheckCircleIcon
                                                    className='task__progress-complete'
                                                    color='success'></CheckCircleIcon>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    )
}
