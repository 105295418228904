interface IUnitBySystem {
    [key: string]: {
        speed: string
        distance: string
        fluidity: string
    }
}

export const UNITS_BY_SYSTEM: IUnitBySystem = {
    metric: {
        speed: 'km/h',
        distance: 'm',
        fluidity: 'sec/km',
    },
    imperial: {
        speed: 'mph',
        distance: 'ft',
        fluidity: 'sec/mi',
    },
}

export const UNIT_TYPE = {
    speed: 'speed',
    distance: 'distance',
    fluidity: 'fluidity',
    density: 'density',
}

export const UNIT_SYSTEM = {
    imperial: 'imperial',
    metric: 'metric',
}