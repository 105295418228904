import React, { useEffect, useState } from 'react'
import {
    Alert,
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    Switch,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../app/hooks'
import { useDispatch } from 'react-redux'
import { CloseRounded as CloseIcon } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { PulseLoader } from 'react-spinners'
import {
    NewManageLiveEvaluationsDialogButton,
    NewManageLiveEvaluationsDialogButtonsWrapper,
    NewManageLiveEvaluationsDialogContentStyled,
    NewManageLiveEvaluationsDialogIconTitleWrapper,
    NewManageLiveEvaluationsDialogName,
    NewManageLiveEvaluationsDialogSeparator,
    NewManageLiveEvaluationsDialogSnackbarStyled,
    NewManageLiveEvaluationsDialogTitle,
    NewManageLiveEvaluationsDialogTitleInfoWrapper,
    NewManageLiveEvaluationsDialogTitleStyled,
    NewManageLiveEvaluationsDialogTitleWrapper,
    NewManageLiveEvaluationsSearchTermWrapper,
    DoNothingScenario,
    NewManageLiveEvaluationsDialogListWrapper,
    NewManageLiveEvaluationsDialogResetButton,
    NewManageLiveEvaluationsSelectRpWrapper,
    NewManageLiveEvaluationsName,
} from './newManageLiveEvaluationDialogStyles'
import CustomIcon from '../../../components/customIcon/CustomIcon'
import SearchTermComponent from '../../../components/searchTermComponent/SearchTermComponent'
import {
    selectOpenEvaluationLiveCreateDialog,
    setEvaluationLiveSelected,
    setOpenEvaluationLiveCreateDialog,
} from '../../evaluationsToolbar/store/evaluationLiveSlice'
import { selectConfig, selectEpoch, selectTimeZone } from '../../core/coreSlice'
import { selectPrecodedResponsePlanLive, selectResponsePlanLives } from './store/responsePlanManageLiveSlice'
import { createNewLiveEvaluationManage } from '../store/evaluationsManageSlice'

const NewManageLiveEvaluationDialog = ({ allViewModes }: NewManageLiveEvaluationDialogProps) => {
    const { t } = useTranslation()
    const _openEvaluationsCreateDialog: boolean = useAppSelector(selectOpenEvaluationLiveCreateDialog)
    const _config: IModuleConfig = useAppSelector(selectConfig)
    const _timeZone: string = useAppSelector(selectTimeZone)
    const _epoch = useAppSelector(selectEpoch)
    const _responsePlanLives = useAppSelector(selectResponsePlanLives)
    const _precodedResponsePlanLive = useAppSelector(selectPrecodedResponsePlanLive)

    const [showFeedback, setShowFeedback] = useState<boolean>(false)
    const [listResponsePlan, setListResponsePlan] = useState<any[]>([])
    const [feedback, setFeedback] = useState<{
        message: string
        type?: 'success' | 'info' | 'warning' | 'error'
        duration: number
    }>({
        message: '',
        duration: 0,
    })
    const [newManageLiveEvaluationsName, setNewManageLiveEvaluationsName] = useState<string>('')
    const [doNothingScenario, setDoNothingScenario] = useState<boolean>(true)
    const [searchResponsePlanTerm, setSearchResponsePlanTerm] = useState<string>('')
    const [responsePlanFiltered, setResponsePlanFiltered] = useState<any[]>([])
    const [showSearchResponsePLanReset, setShowSearchResponsePLanReset] = useState<boolean>(false)
    const [maxResponsePlanViewCount, setMaxResponsePlanViewCount] = useState<number>(25)
    const [selectedResponsePlans, setSelectedResponsePlans] = useState<number[]>([])
    const [postingData, setPostingData] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)

    const dispatch = useDispatch()

    useEffect(() => {
        let responsePlans: any[] = []
        if (Array.isArray(_responsePlanLives)) {
            if (Array.isArray(_precodedResponsePlanLive)) {
                responsePlans = _responsePlanLives.concat(_precodedResponsePlanLive)
            } else {
                responsePlans = _responsePlanLives
            }
        } else if (Array.isArray(_precodedResponsePlanLive)) {
            responsePlans = _precodedResponsePlanLive
        }
        setListResponsePlan(responsePlans)
        setResponsePlanFiltered(responsePlans)
    }, [_responsePlanLives, _precodedResponsePlanLive])

    useEffect(() => {
        const date: string = DateTime.fromMillis(_epoch, { zone: _timeZone }).toFormat(_config.date_format.dateTime)
        setNewManageLiveEvaluationsName(`${t('manageEvaluation.evaluation')} ${date}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClose = () => {
        dispatch(setOpenEvaluationLiveCreateDialog(false))
        dispatch(setEvaluationLiveSelected(false))
        setShowFeedback(false)
        setPostingData(false)
        setNewManageLiveEvaluationsName('')
    }

    const searchResponsePlanTermChange = (searchTerm: string) => {
        setSearchResponsePlanTerm(searchTerm)
        if (searchTerm !== '' && searchTerm.length > 2) {
            const searchRpResult = search(searchTerm)
            setResponsePlanFiltered(searchRpResult)
            setShowSearchResponsePLanReset(true)
        } else {
            setShowSearchResponsePLanReset(false)
            setMaxResponsePlanViewCount(25)
            setResponsePlanFiltered(listResponsePlan)
        }
    }

    const search = (value: string) => {
        const searchResult = listResponsePlan.filter((action: any) =>
            action.name.toLowerCase().includes(value.toLowerCase())
        )
        if (searchResult.length === 0) {
            showResultFeedback(t('manageEvaluation.notFound'), 'warning')
        }
        return searchResult
    }

    const showResultFeedback = (message: string, type: 'success' | 'info' | 'warning' | 'error' = 'info') => {
        setShowFeedback(true)
        setFeedback({
            message: message,
            type: type,
            duration: 3000,
        })
        setTimeout(() => {
            setShowFeedback(false)
        }, 3000)
    }

    const resetResponsePlanClick = () => {
        setSearchResponsePlanTerm('')
        setShowSearchResponsePLanReset(false)
        setResponsePlanFiltered(listResponsePlan)
    }

    const handleCheckedResponsePlan = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _checkedId = Number.parseInt(event.target.value)
        let selected = selectedResponsePlans.slice()

        selected.includes(_checkedId)
            ? (selected = selected.filter(id => _checkedId !== id))
            : selected.push(_checkedId)

        setSelectedResponsePlans(selected)
    }

    const newEvaluationHandler = () => {
        setPostingData(true)
        setDisabled(true)
        const responsePlans: IResponsePlan[] = selectedResponsePlans.map(rpId => {
            const selectedResponsePlan: IResponsePlan = listResponsePlan.find(rp => rpId === rp.id)
            return selectedResponsePlan
        })

        if (doNothingScenario) {
            responsePlans.push({
                id: 0,
                name: 'Do Nothing',
            })
        }

        const evaluation = {
            name: newManageLiveEvaluationsName,
            responsePlans: responsePlans,
            epoch: _epoch,
        }
        dispatch(createNewLiveEvaluationManage({ evaluation, allViewModes }))
    }

    const enableRunButton = newManageLiveEvaluationsName !== '' && selectedResponsePlans.length > 0
    const errorEvaluationName = newManageLiveEvaluationsName === ''

    return (
        <Dialog
            onClose={() => {}}
            maxWidth='xl'
            aria-labelledby='charts-dialog-title'
            open={_openEvaluationsCreateDialog}
            disableEscapeKeyDown={true}>
            <NewManageLiveEvaluationsDialogTitleStyled id='newEvaluation-dialog-title'>
                <NewManageLiveEvaluationsDialogTitleInfoWrapper>
                    <NewManageLiveEvaluationsDialogTitleWrapper>
                        <NewManageLiveEvaluationsDialogIconTitleWrapper>
                            <CustomIcon name='evaluationStatic' style={{ marginTop: '0' }} />
                            <NewManageLiveEvaluationsDialogTitle variant='h2'>
                                {t('manageEvaluation.newEvaluation')}
                            </NewManageLiveEvaluationsDialogTitle>
                        </NewManageLiveEvaluationsDialogIconTitleWrapper>
                        <IconButton aria-label='delete' onClick={handleClose} style={{ width: '30px', height: '30px' }}>
                            <CloseIcon />
                        </IconButton>
                    </NewManageLiveEvaluationsDialogTitleWrapper>
                </NewManageLiveEvaluationsDialogTitleInfoWrapper>
                <NewManageLiveEvaluationsDialogSeparator />
            </NewManageLiveEvaluationsDialogTitleStyled>
            <NewManageLiveEvaluationsDialogContentStyled id='evaluations-live-dialog-content'>
                <NewManageLiveEvaluationsDialogName
                    id='newManageLiveEvaluationsName'
                    value={newManageLiveEvaluationsName}
                    label={t('manageEvaluation.name')}
                    variant={'standard'}
                    autoFocus={true}
                    onChange={e => setNewManageLiveEvaluationsName(e.target.value)}
                    style={{
                        width: '100%',
                        marginBottom: '20px',
                    }}
                    error={errorEvaluationName}
                    helperText={errorEvaluationName ? t('manageEvaluation.fieldRequired') : ''}
                />
                <DoNothingScenario
                    control={
                        <Switch
                            color='primary'
                            checked={doNothingScenario}
                            onChange={e => setDoNothingScenario(e.target.checked)}
                        />
                    }
                    label={t('manageEvaluation.doNothingScenario')}
                    labelPlacement={'start'}
                />
                <NewManageLiveEvaluationsSelectRpWrapper>
                    <NewManageLiveEvaluationsName variant='h2'>
                        {t('manageEvaluation.responsePlan')}
                    </NewManageLiveEvaluationsName>
                    <NewManageLiveEvaluationsSearchTermWrapper>
                        <SearchTermComponent
                            value={searchResponsePlanTerm}
                            startAdornment
                            searchTermChange={searchResponsePlanTermChange}
                            placeholder={t('manageResponsePLan.filter3chars')}
                            id='searchEventsInput'
                        />
                        {showSearchResponsePLanReset && (
                            <NewManageLiveEvaluationsDialogResetButton
                                onClick={resetResponsePlanClick}
                                variant='contained'>
                                <Typography variant='button'>{t('manageResponsePLan.reset')}</Typography>
                            </NewManageLiveEvaluationsDialogResetButton>
                        )}
                    </NewManageLiveEvaluationsSearchTermWrapper>
                    <NewManageLiveEvaluationsDialogListWrapper>
                        {Array.isArray(responsePlanFiltered) && (
                            <FormControl component='fieldset'>
                                <FormGroup>
                                    {responsePlanFiltered.map((event: any) => {
                                        return (
                                            <React.Fragment key={event.id}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={selectedResponsePlans.includes(event.id)}
                                                            onChange={handleCheckedResponsePlan}
                                                            value={event.id}
                                                            color='primary'
                                                        />
                                                    }
                                                    label={`${event.name}`}
                                                />
                                            </React.Fragment>
                                        )
                                    })}
                                </FormGroup>
                            </FormControl>
                        )}
                    </NewManageLiveEvaluationsDialogListWrapper>
                </NewManageLiveEvaluationsSelectRpWrapper>
            </NewManageLiveEvaluationsDialogContentStyled>
            <NewManageLiveEvaluationsDialogButtonsWrapper>
                <NewManageLiveEvaluationsDialogButton onClick={handleClose} variant='text'>
                    <Typography variant='button' color='primary'>
                        {t('buttons.cancel')}
                    </Typography>
                </NewManageLiveEvaluationsDialogButton>
                <NewManageLiveEvaluationsDialogButton
                    disabled={!enableRunButton || disabled}
                    onClick={newEvaluationHandler}
                    variant='contained'>
                    {(postingData && <PulseLoader size={8} margin={2} color='#FFF' />) || (
                        <Typography variant='button'>{t('buttons.create')}</Typography>
                    )}
                </NewManageLiveEvaluationsDialogButton>
            </NewManageLiveEvaluationsDialogButtonsWrapper>
            {showFeedback && (
                <NewManageLiveEvaluationsDialogSnackbarStyled
                    open={showFeedback}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={feedback.duration}
                    onClose={() => {}}>
                    <Alert onClose={() => {}} severity={feedback.type} sx={{ width: '100%' }}>
                        {feedback.message}
                    </Alert>
                </NewManageLiveEvaluationsDialogSnackbarStyled>
            )}
        </Dialog>
    )
}

export default NewManageLiveEvaluationDialog
