import React, { useEffect, useState } from 'react'
import CustomIcon from '../../components/customIcon/CustomIcon'
import { ResponsePlanToolbarIcon, ResponsePlanToolbarLabel, ResponsePlanToolbarWrapper } from './responsePlanToolbarStyles'
import { ResponsePlanCreateDialog } from '../responsePlanCreateDialog/responsePlanCreateDialog'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
    selectOpenResponsePlanCreateDialog,
    selectResponsePlanSelected,
    setOpenResponsePlanCreateDialog,
    setResponsePlanSelected,
} from './store/responsePlanSlice'
import { useTranslation } from 'react-i18next'
import { loadListEvents } from '../eventsToolbar/store/eventsSlice'
import { loadListActions } from '../actionsToolbar/store/actionsSlice'

const ResponsePlanToolbar: React.FC = () => {
    const { t } = useTranslation()
    const _responsePlanIsSelected: boolean = useAppSelector(selectResponsePlanSelected)
    const _openResponsePlanCreateDialog: boolean = useAppSelector(selectOpenResponsePlanCreateDialog)
    const [responsePlanToolbarIsSelected, setResponsePlanToolbarIsSelected] = useState<boolean>(_responsePlanIsSelected)
    const [openResponsePlanDialog, setOpenResponsePlanDialog] = useState<boolean>(_openResponsePlanCreateDialog)

    const dispatch = useAppDispatch()

    useEffect(() => {
        setResponsePlanToolbarIsSelected(_responsePlanIsSelected)
        if (_responsePlanIsSelected) {
            dispatch(loadListEvents())
            dispatch(loadListActions())
        }
    }, [_responsePlanIsSelected])

    useEffect(() => {
        setOpenResponsePlanDialog(_openResponsePlanCreateDialog)
    }, [_openResponsePlanCreateDialog])


    const handleResponsePlanToolbarOnClick = () => {
        setResponsePlanToolbarIsSelected(!responsePlanToolbarIsSelected)
        dispatch(setResponsePlanSelected(!responsePlanToolbarIsSelected))
        dispatch(setOpenResponsePlanCreateDialog(!openResponsePlanDialog))
    }

    return (
        <>
            <ResponsePlanToolbarWrapper onClick={handleResponsePlanToolbarOnClick}>
                <ResponsePlanToolbarIcon className={`${responsePlanToolbarIsSelected ? 'open-responsePlanToolbar-dialog' : ''}`}>
                    <CustomIcon name={'responsePlan'} />
                </ResponsePlanToolbarIcon>
                <ResponsePlanToolbarLabel className={`${responsePlanToolbarIsSelected ? 'open-responsePlanToolbar-dialog' : ''}`}>{t('manageResponsePLan.responsePlan')}</ResponsePlanToolbarLabel>
            </ResponsePlanToolbarWrapper>
            {openResponsePlanDialog && <ResponsePlanCreateDialog />}
        </>
    )
}

export default ResponsePlanToolbar
