import { all, AllEffect, call, ForkEffect, put, select, SelectEffect, takeEvery, takeLatest } from 'redux-saga/effects'
import {
    loadMapData,
    loadMapDataByViewMode,
    loadUpdateAnalyticalData,
    loadUpdateRealData,
    loadUpdateSimulatedData,
} from './mapDataSlice'
import { selectRealData } from '../realData/realDataSlice'
import { selectAnalyticalData, storeAnalyticalData } from '../analyticalData/analyticalDataSlice'
import { selectSimulatedData } from '../simulatedlData/simulatedDataSlice'
import { initRealData } from '../realData/realDataSaga'
import { initAnalyticalData } from '../analyticalData/analyticalDataSaga'
import { initSimulatedData } from '../simulatedlData/simulatedDataSaga'
import {
    selectAllMapData,
    setVisibilityDetectors,
    setVisibilitySections,
    storeFetchingDataByMap,
    storeMapboxData,
} from '../../mapbox/mapboxSlice'
import { selectHorizon } from '../../horizonSlider/horizonSilderSlice'
import { selectConfig, setAnalyticalDataUpdates, setRealDataUpdates, setSimulatedDataUpdates } from '../coreSlice'
import { initRiskPredictionData } from '../riskPredictionData/riskPredictionDataSaga'
import { selectRiskPredictionData } from '../riskPredictionData/riskPredictionDataSlice'
import {
    IStationSelectedData,
    loadStationSelected,
    selectStationFeatureId,
} from '../../stationDialog/store/stationSelectedSlice'
import { getColorByReliability, getColorByValue } from '../../../helpers/MapboxHelper'
import { getMarks } from '../../../helpers/SliderMarksHelper'
import { initSpeedRecommendationData } from '../speedRecommendationData/speedRecommendationDataSaga'
import {
    selectSpeedRecommendationData,
    storeSpeedRecommendationData,
} from '../speedRecommendationData/speedRecommendationDataSlice'

export const hasTypeData = (viewMode: IViewMode, type: string): boolean => {
    return (
        viewMode.sources.sections.now === type ||
        viewMode.sources.stations.now === type ||
        viewMode.sources.sections.future === type ||
        viewMode.sources.stations.future === type
    )
}

const hasRiskPrediction = (viewMode: IViewMode): boolean => {
    return viewMode.sources.sections.future === 'analytical'
}

const sourceSectionsVisible = (viewMode: IViewMode): boolean => {
    return !(viewMode.sources.sections.future === 'na' && viewMode.sources.sections.now === 'na')
}

const sourceStationsVisible = (viewMode: IViewMode): boolean => {
    return viewMode.sources.stations.future !== 'na' && viewMode.sources.stations.now !== 'na'
}

function* getData(
    action: any,
    dataType: { real: boolean; analytical: boolean; simulated: boolean; riskPrediction: boolean }
) {
    if (dataType.real) yield call(initRealData, action)

    if (dataType.analytical) yield call(initAnalyticalData, action)

    if (dataType.simulated) yield call(initSimulatedData, action)

    if (dataType.riskPrediction) yield call(initRiskPredictionData, action)
}

export function* getDataFromStore(
    typeData: string,
    variable: string,
    typeTime: string
): Generator<SelectEffect, any, any> {
    let data: any
    switch (typeData) {
        case 'real':
            const realData: any = yield select(selectRealData)
            data = typeTime === 'now' && realData ? realData[variable][0] : realData[variable]
            break
        case 'analytical':
            const analyticalData: any = yield select(selectAnalyticalData)
            data = typeTime === 'now' && analyticalData ? analyticalData[variable][0] : analyticalData[variable]
            break
        case 'simulated':
            const simulatedData: any = yield select(selectSimulatedData)
            data = typeTime === 'now' && simulatedData ? simulatedData[variable][0] : simulatedData[variable]
            break
        case 'na':
        default:
            data = []
            break
    }
    return data
}

export function* getRiskPredictionFrommStore(typeData: string): Generator<SelectEffect, any, any> {
    let data: any
    switch (typeData) {
        case 'analytical':
            data = yield select(selectRiskPredictionData)
            break
        case 'simulated':
        case 'na':
        default:
            data = [{ features: [], type: 'FeatureCollection' }]
            break
    }
    return data
}

function* storeMapData(mapId: number, viewModeId: number, viewMode: IViewMode, epoch: number, qm: boolean): any {
    const horizon = yield select(selectHorizon)
    const _moduleConfig: IModuleConfig = yield select(selectConfig)
    const step: number = _moduleConfig['horizon-step']
    const stationNowData = yield call(getDataFromStore, viewMode.sources.stations.now, viewMode.variable, 'now')
    const stationFutureData = yield call(
        getDataFromStore,
        viewMode.sources.stations.future,
        viewMode.variable,
        'future'
    )
    const sectionNowData = yield call(getDataFromStore, viewMode.sources.sections.now, viewMode.variable, 'now')
    const sectionFutureData = yield call(
        getDataFromStore,
        viewMode.sources.sections.future,
        viewMode.variable,
        'future'
    )
    const riskPredictionData = yield call(getRiskPredictionFrommStore, viewMode.sources.sections.future)
    const speedRecommendationData = yield select(selectSpeedRecommendationData)
    const feature: IStationSelectedData = yield select(state => selectStationFeatureId(state, mapId))
    const values = stationNowData[feature.feature_id]
    if (values) {
        const valueColor = getColorByValue(viewMode, values.value)
        const reliabilityColor = getColorByReliability(viewMode, values.reliability)
        yield put(
            loadStationSelected({
                mapId: mapId,
                feature_id: feature.feature_id,
                t: epoch,
                viewMode: viewMode,
                valueColor: valueColor,
                reliabilityColor: reliabilityColor,
            })
        )
    }
    yield put(
        storeMapboxData({
            stationData: horizon > 0 ? stationFutureData[horizon / step] || [] : stationNowData,
            sectionData: horizon > 0 ? sectionFutureData[horizon / step] || [] : sectionNowData,
            mapId,
            viewModeId,
            stationNowData,
            stationFutureData,
            sectionNowData,
            sectionFutureData,
            riskPrediction: riskPredictionData.length > 1 ? riskPredictionData[horizon / step] : riskPredictionData[0],
            riskPredictionData,
            speedRecommendationData: speedRecommendationData,
            speedRecommendation: speedRecommendationData[0],
            qm: qm,
        })
    )
}

function* initMapData(action: any): any {
    const allMapboxData: IDataByMap[] = yield select(selectAllMapData)
    const selectedViewModes: IViewMode[] = allMapboxData
        .filter(dataByMap => !dataByMap.qm)
        .map((mapboxData: IDataByMap) => {
            return action.payload.allViewMode.find(({ id }: IViewMode) => id === mapboxData.viewModeId)
        })
    const qmMapIds = allMapboxData.filter(dataByMap => !dataByMap.qm).map(dataByMap => dataByMap.mapId)
    yield all(qmMapIds.map(mapId => put(storeFetchingDataByMap(mapId))))
    yield all(
        selectedViewModes.map((viewMode: IViewMode) => {
            let newAction = action
            newAction = {
                ...newAction,
                payload: {
                    epoch: action.payload.epoch,
                    variable: viewMode.variable,
                },
            }
            const typeData = {
                real: hasTypeData(viewMode, 'real'),
                analytical: hasTypeData(viewMode, 'analytical'),
                simulated: hasTypeData(viewMode, 'simulated'),
                riskPrediction: hasRiskPrediction(viewMode),
            }
            return call(getData, newAction, typeData)
        })
    )

    yield all(
        allMapboxData
            .filter(dataByMap => !dataByMap.qm)
            .map((dataByMap: IDataByMap) => {
                const selectedViewMode = action.payload.allViewMode.find(
                    ({ id }: IViewMode) => id === dataByMap.viewModeId
                )
                const sections_visibility = sourceSectionsVisible(selectedViewMode)
                return put(setVisibilitySections({ mapId: dataByMap.mapId, sections_visibility }))
            })
    )

    yield all(
        allMapboxData
            .filter(dataByMap => !dataByMap.qm)
            .map((dataByMap: IDataByMap) => {
                const selectedViewMode = action.payload.allViewMode.find(
                    ({ id }: IViewMode) => id === dataByMap.viewModeId
                )
                const detectors_visibility = sourceStationsVisible(selectedViewMode)
                return put(setVisibilityDetectors({ mapId: dataByMap.mapId, detectors_visibility }))
            })
    )
    yield call(initSpeedRecommendationData, action)

    yield all(
        allMapboxData
            .filter(dataByMap => !dataByMap.qm)
            .map((dataByMap: IDataByMap) =>
                call(
                    storeMapData,
                    dataByMap.mapId,
                    dataByMap.viewModeId,
                    action.payload.allViewMode.find(({ id }: IViewMode) => id === dataByMap.viewModeId),
                    action.payload.epoch,
                    false
                )
            )
    )
    yield put(setRealDataUpdates(false))
}

function* updateAnalyticalData(action: any): any {
    const allMapboxData: IDataByMap[] = yield select(selectAllMapData)
    const selectedViewModes: IViewMode[] = allMapboxData
        .filter(dataByMap => !dataByMap.qm)
        .map((mapboxData: IDataByMap) => {
            return action.payload.allViewMode.find(({ id }: IViewMode) => id === mapboxData.viewModeId)
        })

    yield all(
        selectedViewModes.map((viewMode: IViewMode) => {
            let newAction = action
            newAction = {
                ...newAction,
                payload: {
                    epoch: action.payload.epoch,
                    variable: viewMode.variable,
                },
            }
            const typeData = {
                real: false,
                analytical: hasTypeData(viewMode, 'analytical'),
                simulated: false,
                riskPrediction: hasRiskPrediction(viewMode),
            }
            return call(getData, newAction, typeData)
        })
    )
    yield call(initSpeedRecommendationData, action)

    yield all(
        allMapboxData
            .filter(dataByMap => !dataByMap.qm)
            .map((dataByMap: IDataByMap) => {
                const selectedViewMode = action.payload.allViewMode.find(
                    ({ id }: IViewMode) => id === dataByMap.viewModeId
                )
                const sections_visibility = sourceSectionsVisible(selectedViewMode)
                return put(setVisibilitySections({ mapId: dataByMap.mapId, sections_visibility }))
            })
    )

    yield all(
        allMapboxData
            .filter(dataByMap => !dataByMap.qm)
            .map((dataByMap: IDataByMap) => {
                const selectedViewMode = action.payload.allViewMode.find(
                    ({ id }: IViewMode) => id === dataByMap.viewModeId
                )
                const detectors_visibility = sourceStationsVisible(selectedViewMode)
                return put(setVisibilityDetectors({ mapId: dataByMap.mapId, detectors_visibility }))
            })
    )

    yield all(
        allMapboxData
            .filter(dataByMap => !dataByMap.qm)
            .map((dataByMap: IDataByMap) =>
                call(
                    storeMapData,
                    dataByMap.mapId,
                    dataByMap.viewModeId,
                    action.payload.allViewMode.find(({ id }: IViewMode) => id === dataByMap.viewModeId),
                    action.payload.epoch,
                    action.payload.qm
                )
            )
    )
    yield put(setAnalyticalDataUpdates(false))
}

function* updateRealData(action: any): any {
    const allMapboxData: IDataByMap[] = yield select(selectAllMapData)
    const selectedViewModes: IViewMode[] = allMapboxData
        .filter(dataByMap => !dataByMap.qm)
        .map((mapboxData: IDataByMap) => {
            return action.payload.allViewMode.find(({ id }: IViewMode) => id === mapboxData.viewModeId)
        })

    yield all(
        selectedViewModes.map((viewMode: IViewMode) => {
            let newAction = action
            newAction = {
                ...newAction,
                payload: {
                    epoch: action.payload.epoch,
                    variable: viewMode.variable,
                },
            }
            const typeData = {
                real: hasTypeData(viewMode, 'real'),
                analytical: false,
                simulated: false,
                riskPrediction: hasRiskPrediction(viewMode),
            }
            return call(getData, newAction, typeData)
        })
    )
    yield call(initSpeedRecommendationData, action)
    const _moduleConfig: IModuleConfig = yield select(selectConfig)
    const step: number = _moduleConfig['horizon-step']
    const numberOfHorizon: number = _moduleConfig['horizon-count']
    const marks = getMarks(step, numberOfHorizon)
    const predictionData: IPredictionByHorizonData[] = marks.map(() => [])

    yield all(
        selectedViewModes.map((viewMode: IViewMode) =>
            put(storeAnalyticalData({ data: predictionData, variable: viewMode.variable }))
        )
    )

    yield all(
        allMapboxData
            .filter(dataByMap => !dataByMap.qm)
            .map((dataByMap: IDataByMap) => {
                const selectedViewMode = action.payload.allViewMode.find(
                    ({ id }: IViewMode) => id === dataByMap.viewModeId
                )
                const sections_visibility = sourceSectionsVisible(selectedViewMode)
                return put(setVisibilitySections({ mapId: dataByMap.mapId, sections_visibility }))
            })
    )

    yield all(
        allMapboxData
            .filter(dataByMap => !dataByMap.qm)
            .map((dataByMap: IDataByMap) => {
                const selectedViewMode = action.payload.allViewMode.find(
                    ({ id }: IViewMode) => id === dataByMap.viewModeId
                )
                const detectors_visibility = sourceStationsVisible(selectedViewMode)
                return put(setVisibilityDetectors({ mapId: dataByMap.mapId, detectors_visibility }))
            })
    )

    yield all(
        allMapboxData
            .filter(dataByMap => !dataByMap.qm)
            .map((dataByMap: IDataByMap) =>
                call(
                    storeMapData,
                    dataByMap.mapId,
                    dataByMap.viewModeId,
                    action.payload.allViewMode.find(({ id }: IViewMode) => id === dataByMap.viewModeId),
                    action.payload.epoch,
                    action.payload.qm
                )
            )
    )

    yield put(setRealDataUpdates(false))
}

function* updateSimulatedData(action: any): any {
    const allMapboxData: IDataByMap[] = yield select(selectAllMapData)
    const selectedViewModes: IViewMode[] = allMapboxData
        .filter(dataByMap => !dataByMap.qm)
        .map((mapboxData: IDataByMap) => {
            return action.payload.allViewMode.find(({ id }: IViewMode) => id === mapboxData.viewModeId)
        })

    yield all(
        selectedViewModes.map((viewMode: IViewMode) => {
            let newAction = action
            newAction = {
                ...newAction,
                payload: {
                    epoch: action.payload.epoch,
                    variable: viewMode.variable,
                },
            }
            const typeData = {
                real: false,
                analytical: false,
                simulated: hasTypeData(viewMode, 'simulated'),
                riskPrediction: false,
            }
            return call(getData, newAction, typeData)
        })
    )
    yield call(initSpeedRecommendationData, action)

    yield all(
        allMapboxData
            .filter(dataByMap => !dataByMap.qm)
            .map((dataByMap: IDataByMap) => {
                const selectedViewMode = action.payload.allViewMode.find(
                    ({ id }: IViewMode) => id === dataByMap.viewModeId
                )
                const sections_visibility = sourceSectionsVisible(selectedViewMode)
                return put(setVisibilitySections({ mapId: dataByMap.mapId, sections_visibility }))
            })
    )

    yield all(
        allMapboxData
            .filter(dataByMap => !dataByMap.qm)
            .map((dataByMap: IDataByMap) => {
                const selectedViewMode = action.payload.allViewMode.find(
                    ({ id }: IViewMode) => id === dataByMap.viewModeId
                )
                const detectors_visibility = sourceStationsVisible(selectedViewMode)
                return put(setVisibilityDetectors({ mapId: dataByMap.mapId, detectors_visibility }))
            })
    )

    yield all(
        allMapboxData
            .filter(dataByMap => !dataByMap.qm)
            .map((dataByMap: IDataByMap) =>
                call(
                    storeMapData,
                    dataByMap.mapId,
                    dataByMap.viewModeId,
                    action.payload.allViewMode.find(({ id }: IViewMode) => id === dataByMap.viewModeId),
                    action.payload.epoch,
                    action.payload.qm
                )
            )
    )
    yield put(setSimulatedDataUpdates(false))
}

function* updateSpeedRecommendationData(): any {
    const allMapboxData: IDataByMap[] = yield select(selectAllMapData)
    const speedRecommendationData = yield select(selectSpeedRecommendationData)

    yield all(
        allMapboxData
            .filter(dataByMap => !dataByMap.qm)
            .map((dataByMap: IDataByMap) =>
                put(
                    storeMapboxData({
                        stationData: dataByMap.stationNowData,
                        sectionData: dataByMap.sectionNowData,
                        mapId: dataByMap.mapId,
                        viewModeId: dataByMap.viewModeId,
                        stationNowData: dataByMap.stationNowData,
                        stationFutureData: dataByMap.stationFutureData,
                        sectionNowData: dataByMap.sectionNowData,
                        sectionFutureData: dataByMap.sectionFutureData,
                        riskPrediction: dataByMap.riskPrediction,
                        riskPredictionData: dataByMap.riskPredictionData,
                        speedRecommendationData: speedRecommendationData,
                        speedRecommendation: speedRecommendationData[0],
                        qm: dataByMap.qm,
                    })
                )
            )
    )
    yield put(setSimulatedDataUpdates(false))
}

function* getMapDataByViewMode(action: any) {
    const { epoch, allViewMode, viewModeId, mapId, qm } = action.payload
    const selectedViewMode = allViewMode.find(({ id }: IViewMode) => id === viewModeId)

    let actionToCall = action
    actionToCall = {
        ...actionToCall,
        payload: {
            epoch: epoch,
            variable: selectedViewMode.variable,
        },
    }
    const typeData = {
        real: hasTypeData(selectedViewMode, 'real'),
        analytical: hasTypeData(selectedViewMode, 'analytical'),
        simulated: hasTypeData(selectedViewMode, 'simulated'),
        riskPrediction: hasRiskPrediction(selectedViewMode),
    }
    const detectors_visibility = sourceStationsVisible(selectedViewMode)
    const sections_visibility = sourceSectionsVisible(selectedViewMode)
    yield put(setVisibilityDetectors({ mapId, detectors_visibility }))
    yield put(setVisibilitySections({ mapId, sections_visibility }))
    yield put(storeFetchingDataByMap(mapId))
    yield call(getData, actionToCall, typeData)
    yield call(storeMapData, mapId, viewModeId, selectedViewMode, epoch, qm)
}

function* mapDataSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([
        yield takeLatest(loadMapData, initMapData),
        yield takeLatest(loadUpdateRealData, updateRealData),
        yield takeLatest(loadUpdateAnalyticalData, updateAnalyticalData),
        yield takeLatest(loadUpdateSimulatedData, updateSimulatedData),
        yield takeEvery(loadMapDataByViewMode, getMapDataByViewMode),
        yield takeEvery(storeSpeedRecommendationData, updateSpeedRecommendationData),
    ])
}

export default mapDataSaga
