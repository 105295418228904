import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

const initialState: IViewState = {
    views_layout: 1,
    open: false,
}

export const viewSlice = createSlice({
    name: 'view',
    initialState,
    reducers: {
        changeViewsLayout: (state: IViewState, action: PayloadAction<any>): void => {},
        changeDataByMap: (state: IViewState, action: PayloadAction<any>): void => {},
        storeViewsLayout: (state: IViewState, action: PayloadAction<number>): void => {
            state.views_layout = action.payload
            state.open = false
        },
        toggleViews: (state: IViewState): void => {
            state.open = !state.open
        },
    },
})

export const { storeViewsLayout, toggleViews, changeViewsLayout, changeDataByMap } = viewSlice.actions

export const selectViewsLayout = (state: RootState): number => state.view.views_layout
export const selectViewOpen = (state: RootState): boolean => state.view.open

export default viewSlice.reducer
