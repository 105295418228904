import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

const initialQualityManagerState: IQualityManagerState = {
    loading: false,
    error: '',
    qmData: [],
}

const qualityManagerDialogSlice = createSlice({
    name: 'qualityManager',
    initialState: initialQualityManagerState,
    reducers: {
        storeQmData: (state, action: PayloadAction<any>): void => {
            state.qmData = action.payload
        },
        getSimulatedVsRealQuality: (state, action: PayloadAction<ISimulatedVsRealQualityAction>): void => {
            state.loading = true
        },
        getQMDMData: (state, action: PayloadAction<IQMDMDataAction>): void => {
            state.loading = true
        },
        getQMDMAverages: (state, action: PayloadAction<IQMDMAverages>): void => {
            state.loading = true
        },
        getQMDMVariability: (state, action: PayloadAction<IQMDMVariability>): void => {
            state.loading = true
        },
        setLoadingQmData: (state, action: PayloadAction<boolean>): void => {
            state.loading = action.payload
        },
        setQmError: (state, action: PayloadAction<string>): void => {
            state.error = action.payload
        },
    },
})

export const {
    storeQmData,
    setLoadingQmData,
    setQmError,
    getSimulatedVsRealQuality,
    getQMDMData,
    getQMDMAverages,
    getQMDMVariability,
} = qualityManagerDialogSlice.actions

export const selectQmData = (state: RootState) => state.qualityManager.qmData
export const selectLoadingQmData = (state: RootState) => state.qualityManager.loading
export const selectQmError = (state: RootState) => state.qualityManager.error

export default qualityManagerDialogSlice.reducer
