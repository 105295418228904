import { UNIT_SYSTEM, UNITS_BY_SYSTEM } from '../constans/unitsConstans'
import { converterToMetricSystem } from './unitsHelper'
import { IRawKpi } from '../containers/analyzeContainer/components/kpi/store/kpisSlice'

export const getKpisConverted = (unitSystem: string, kpis: IRawKpi[]) => {
    if (unitSystem === UNIT_SYSTEM.imperial) {
        return kpis.map((kpi) => {
            let kpiConverted = kpi
            if (kpi.name.toLowerCase().includes('fluidity')) {
                kpiConverted = {
                    ...kpiConverted,
                    units: UNITS_BY_SYSTEM.imperial.fluidity,
                    value: converterToMetricSystem('fluidity', kpi.value)
                }
            }
            return kpiConverted
        })
    }
    return kpis
}